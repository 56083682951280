

























































































































































































































import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import PageTitle from '@/components/PageTitle.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Guid } from 'guid-typescript'
import flatPickr from 'vue-flatpickr-component'
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { SelectOption } from 'piramis-base-components/src/logic/types'

export interface ChatProperty {
  id?: number,
  type: ChatPropertyType,
  name: string,
  default_value: any
}

export enum ChatPropertyType {
  Number = 'Number',
  String = 'String',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Double = 'Double',
  Flag = 'Flag',
}

@Component({
  'components': {
    PageTitle,
    ConfigField,
    flatPickr,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout
  },
  data() {
    return {
      ChatPropertyType
    }
  }
})
export default class ChatProperties extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  properties: Array<ChatProperty> = []

  propertyItem = <ChatProperty>{ type: ChatPropertyType.String }

  propertyTypes:Array<SelectOption> = [
    { label: this.$t('property_type_number'), value: ChatPropertyType.Number },
    { label: this.$t('property_type_string'), value: ChatPropertyType.String },
    { label: this.$t('property_type_date'), value: ChatPropertyType.Date },
    { label: this.$t('property_type_time'), value: ChatPropertyType.Time },
    { label: this.$t('property_type_datetime'), value: ChatPropertyType.DateTime },
    { label: this.$t('property_type_double'), value: ChatPropertyType.Double },
    { label: this.$t('property_type_flag'), value: ChatPropertyType.Flag }
  ]

  selectedType = ChatPropertyType.String

  isOpen = false

  isEditMode = false

  itemToEdit = {}

  columns = [
    { title: this.$t('property_name'), key: 'name', dataIndex: 'name' },
    { title: this.$t('field_default_value_title'), key: 'default_value', dataIndex: 'default_value' },
    { title: this.$t('property_type'), key: 'type', dataIndex: 'type',     scopedSlots: { customRender: 'type' }, },
    { title: this.$t('property_action'), key: 'actions', scopedSlots: { customRender: 'actions' } },
  ]

  get doubleValueComputed(): number {
    return this.propertyItem.default_value
  }

  set doubleValueComputed(value: number) {
    this.propertyItem.default_value = Number(value.toFixed(2))
  }

  get isOkDisabled(): boolean {
    if (this.selectedType === ChatPropertyType.Flag) {
      return !this.propertyItem.name
    } else if ([ ChatPropertyType.Number, ChatPropertyType.Double ].includes(this.selectedType)) {
      return !this.propertyItem.name || isNaN(this.propertyItem.default_value)
    } else {
      return !this.propertyItem.name || !this.propertyItem.default_value
    }
  }

  resetPropertyModel():void {
    this.propertyItem = <ChatProperty>{
      type: ChatPropertyType.String,
      name: '',
      default_value: '',
    }

    this.selectedType = ChatPropertyType.String
  }

  @Watch('$store.state.chatState.chat.config.property_items')
  onPropertiesChange() {
    this.updateProperties()
  }

  getIcon(type: ChatPropertyType) {
    switch (type) {
      case ChatPropertyType.Number:
        return `<i class="material-icons"> pin </i>`
      case ChatPropertyType.Date:
        return `<i class="material-icons"> date_range </i>`
      case ChatPropertyType.Time:
        return `<i class="material-icons"> access_time </i>`
      case ChatPropertyType.DateTime:
        return `<i class="material-icons"> pending_actions </i>`
      case ChatPropertyType.Flag:
        return `<i class="material-icons"> toggle_off </i>`
      case ChatPropertyType.Double:
        return `<i class="material-icons"> calculate </i>`
      case ChatPropertyType.String:
        return `<i class="material-icons"> text_fields </i>`
      default:
        throw new Error(`Unknown property type, got: ${ type }`)
    }
  }

  onCancel():void {
    this.resetPropertyModel()
  }

  handleOkClick():void {
    if (this.isEditMode) {
      this.updateProperty(this.propertyItem)
    } else {
      this.createProperty()
    }
  }

  savePropertyToStore(): void {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.property_items = this.properties
          .slice()
          .map((item: any) => {
            const { ...data }: { guid?: string } & ChatProperty = item
            delete data.guid
            return data
          })
      }
    })

    this.isOpen = false
  }

  createProperty() {
    this.properties = [ this.setGuidToProperty(this.propertyItem), ...this.properties ]
    this.savePropertyToStore()
  }

  editProperty(item: ChatProperty) {
    this.isEditMode = true
    this.isOpen = true
    this.selectedType = this.propertyTypes.find((p: any) => p.value === item.type)!.value
    this.propertyItem = { ...item }
  }

  removePropertyFrontend(item: ChatProperty, index: number) {
    this.$confirm({
      title: this.$t('confirm_remove_property').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.properties.splice(index, 1)
        this.savePropertyToStore()
      },
    })
  }

  updateProperties(): void {
    this.properties = this.$store.state.chatState.chat.config.property_items.slice().map((item: ChatProperty) => {
      return this.setGuidToProperty(item)
    })
  }

  updateProperty(itemToEdit: any): void {
    this.properties = this.properties.slice().map((item: any) => {
      return item.guid === itemToEdit.guid ? item = { ...itemToEdit } : item
    })
    this.savePropertyToStore()
  }

  getPropertyType(type: string): any {
    return this.propertyTypes.find((t: any) => t.value === type)!.label
  }

  setGuidToProperty(item: ChatProperty): any {
    return { 'guid': Guid.create().toString(), ...item }
  }

  onSelect(selectedPropertyType:SelectOption): void {
    this.propertyItem.type = selectedPropertyType.value
  }

  onSelectChange(selectedOption: SelectOption): void {
    switch (selectedOption.value as ChatPropertyType) {
      case ChatPropertyType.Number:
        this.$set(this.propertyItem, 'default_value', 0)
        break
      case ChatPropertyType.Double:
        this.$set(this.propertyItem, 'default_value', 0.1)
        break
      case ChatPropertyType.Flag:
        this.$set(this.propertyItem, 'default_value', false)
        break
      default:
        this.$set(this.propertyItem, 'default_value', '')
        break
    }
  }

  mounted(): void {
    this.updateProperties()
  }
}
